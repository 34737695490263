@import './Settings.scss';

.grid-container {
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-flow: row wrap;
  align-self: center;
}

.work-item {
  width: 100%;
  margin-bottom: 20px;
  flex-shrink: 1;
  flex: 0 0 auto;

  button {
    background-color: rgba(0,0,0,0);
    border-color:  rgba(0,0,0,0);
    border:  0;
    padding:  0;
    margin:  0;
    color:  rgba(0,0,0,0);
    text-align: left;
    font-size: 1em;
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  .inner {
    margin: 20px 20px 0 20px;
    position: relative;
  }

  figure {
    width: 100%;
    overflow: hidden;
    margin: 0;
    padding: 0;
    display: block;
    margin-bottom: 10px;

    img {
      transform: scale(1);
      transition: 0.3s ease-in-out;
      width: 100%;
      height: auto;
      filter: brightness(1);
      display: block;
    }
  }

  &:hover {
    figure {
      img {
        transform: scale(1.3) rotate(1.5deg);
        filter: brightness(1.4);
      }
    }

    h3 {
      color: #fff;
    }

    h4 {
      color: #fff;
    }

    p {
      color: #fff;
    }

    .details-link {
      color: #fff;
      text-decoration: underline;
    }
  }

  h3 {
    font-size: 1.3em;
    margin-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin-top: 0;
    transform: scale(1);
    transition: 0.3s ease-in-out;
  }

  h4 {
    @include eyebrow();
    margin-bottom: 3px;
    transform: scale(1);
    transition: 0.3s ease-in-out;
  }

  .details-link {
    @include avenir(bold);
    text-transform: uppercase;
    text-decoration: none;
    color: $primary-color;
    font-size: 0.9em;
    letter-spacing: 1px;
    transform: scale(1);
    transition: 0.3s ease-in-out;
  }

  p {
    transform: scale(1);
    transition: 0.3s ease-in-out;
  }
}

@media (min-width: 576px) {
  .work-item {
    width: 50%;
  }
}
@media (min-width: 1200px) {
  .work-item {
    width: 33.33333%;
  }
}
