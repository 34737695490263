@import './Settings.scss';

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}


body {
  padding: 0;
  margin: 0;
  height: 100%;
  background-color: $third-color;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

.hide-for-small {
  @media (max-width: 767px) {
    display: none;
  }
}

.bg {
  position: fixed;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  width: 200%;
  height: 200vh;
  background: transparent url('./noise-transparent.png') repeat 0 0;
  background-repeat: repeat;
  animation: bg-animation 0.2s infinite;
  opacity: 0.5;
  visibility: visible;
  z-index: -1;
}

.section {
  position: relative;
  max-width: 1440px;
  margin: 30px auto;
  background: none;
  padding: 2%;
  z-index: 2;
  transition: all 0.4s ease-in-out;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 768px) {
    margin: 0 auto 30px auto;;
  }
}

p {
  @include avenir();
  color: $primary-color;
  margin-top: 0;
  font-size: 0.9em;

  @media (min-width: 768px) {
    font-size: 1em;
  }
}

h1 {
  font-family: "Compressa VF";
  font-weight: bold;
  color: $primary-color;
  text-rendering: optimizeSpeed;
  color: $accent-color;
  width: 100%;
  user-select: none;
  line-height: 0.8em;
  margin: 0 auto;
  text-transform: uppercase;
  font-weight: 100;
  text-align: center;
  width: 100vw;
  
  span {
    transform: translateY(-10px);
    user-select: none;
  }

  &.flex {
    display: flex;
    justify-content: space-between;
  }

  &.stroke {
    span {
      color: $accent-color;
      position: relative;
      line-height: inherit;

      &:after {
        content: attr(data-char);
        -webkit-text-stroke-width: 3px;
        -webkit-text-stroke-color: #fe6730;
        position: absolute;
        left: 0;
        line-height: inherit;
        color: transparent;
        z-index: -1;
      }
    }
  }
}

h2, h3{
  @include avenir(bold);
  color: $primary-color;
}

h4 {
 color: $primary-color; 
}

.playground {
  .work-item {
    width: 50%;
    margin-bottom: 0;

    h3 {
      text-align: center;
      font-size: 0.9em;
    }
  }
}


@media (min-width: 992px) {
  .playground {
    .work-item {
      width: 33%;
    }
  }
}

@keyframes bg-animation {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -5%);
  }
  20% {
    transform: translate(-10%, 5%);
  }
  30% {
    transform: translate(5%, -10%);
  }
  40% {
    transform: translate(-5%, 15%);
  }
  50% {
    transform: translate(-10%, 5%);
  }
  60% {
    transform: translate(15%, 0);
  }
  70% {
    transform: translate(0, 10%);
  }
  80% {
    transform: translate(-15%, 0);
  }
  90% {
    transform: translate(10%, 5%);
  }
  100% {
    transform: translate(5%, 0);
  }
}
