@import './Settings.scss';

.contact {
  padding-bottom: 150px;
}

.form {
  padding: 0 20px;
  max-width: 900px;
  margin: 0 auto;

  .row-2 {
    display: block;

    @media (min-width: 768px) { 
      display: flex;
      flex: 1 0 auto;

      .email {
        margin-left: 20px;
      }
    }
  }

  input { 
    width: 100%;
    height: 50px;
    margin-bottom: 20px;

    @media (min-width: 768px) { 
      width: 50%;
    }
  }

  textarea {
    width: 100%;
    height: 200px;
  }

  input, textarea {
    @include avenir();
    background: #fff;
    border: 0;
    letter-spacing: 0.5px;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 10px;
    font-size: 1em;
    border-radius: 0;

    &:focus {
      outline: 0;
      border: 0;
    }

    &.error {
      border: 2px solid #ff0000;

      &:focus {
        border: 0;
      }
    }
  }

  &::placeholder {
    color: $third-color;
  }

  .button {
    @include avenir(bold);
    background: $accent-color;
    color: $third-color;
    transition: 0.3s ease-in-out;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    text-align: center;
    vertical-align: middle;
    padding: 0;
    font-size: 1em;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:hover {
      cursor: pointer;
      color: #fff;
    }
  }

  label {
    @include avenir(bold);
    color: $primary-color;
    text-transform: uppercase;
    font-size: 1em;
    display: block;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    padding: 0 40px;
  }

  @media (min-width: 992px) {
    width: 80vw;

    input {
      font-size: 1.1em;
    }

    textarea {
      font-size: 1.1em;
    }

    button, .button {
      font-size: 1.1em;
    }
  }
}

#contact-form {
  &.success {
    transform: scale(0);
    transition: transform .5s;
  }
}

.grecaptcha-badge { 
  visibility: hidden;
}