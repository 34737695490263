@import './Settings.scss';


body {
  &.overlay-active {
    overflow: hidden;
    
    .overlay {
      transform: scale(1);
      opacity: 1;
    }

    .close-btn {
      transform: scale(1);
      transition-delay: .5s;
    }

    .section {
      filter: blur(25px);
    }
  }
}

.overlay {
  width: 100vw;
  height: 100vh;
  transform: scale(0);
  opacity: 0.2;
  background: none;
  position: fixed;
  z-index: 3333;
  top: 0;
  left: 0;
  transition: all 500ms cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);

  .bg {
    position: fixed;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    width: 200%;
    height: 200vh;
    background: transparent url('./noise-transparent.png') repeat 0 0;
    background-repeat: repeat;
    animation: bg-animation 0.2s infinite;
    opacity: 0.9;
    visibility: visible;
    z-index: -1;
  }

  .inner::-webkit-scrollbar {
    display: none;
  }

  .inner {
    position: relative;
    width: 100vw;
    height: 100%;
    z-index: 2;
    margin: 0 auto 50px auto;
    text-align: center;
    overflow-y: auto;

    .content {
      width: 90%;
      max-width: 1000px;
      margin: 60px auto;
    }

    .work-overlay-title {
      color: #fff;
      font-size: 1.8em;
      margin: 0 auto 10px auto;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      line-height: 1em;
      
      @media (min-width: 768px) {
        font-size: 2.3em;
      }
    }

    .work-overlay-brand {
      font-size: 0.8em;
      margin: 0 auto 10px auto;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      color: $accent-color;
    }

    p {
      color: #fff;
      max-width: 700px;
      margin: 0 auto 20px auto;
    }

    .work-item-title {
      font-size: 1em;
      margin: 0 auto 5px auto;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      color: #fff;
      text-align: center;
      width: 100%
    }
  }

  .close-btn {
    @include avenir(bold);
    color: $third-color;
    background: $accent-color;
    width: 30px;
    height: 30px;
    position: fixed;
    top: 10px;
    right: 20px;
    font-size: 17px;
    border-radius: 1000px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transform: scale(0);
    transition-property: transform;
    transition-delay: 0s;
    transition-duration: 0s;

    @media (min-width: 768px) {
      top: 20px;
      right: 30px;
    }
    
    &:hover {
      color: #fff;
    }
  }

  .overlay-item {
    margin-bottom: 20px;

    @media (min-width: 768px) {
      margin-bottom: 40px;
    }

    p {
      color: #fff;
    }

    img, video {
      width: 100%;
      height: auto;
    }
  }
}

.overlay-title-outer {
  width: 100%;
  text-align: center;
}

.overlay-item-outer {
  width: 100%;
  display: block;

  @media (min-width: 481px) {
    display: flex;
    margin: 0 10px;
  }

  div {
    padding: 0 10px;
  }
}

.overlay-item-inner {
  margin-bottom: 20px;

  @media (min-width: 481px) {
    margin-bottom: 0;
  }
}

.overlay-header {
  margin-bottom: 60px;
}

.overlay-roles {
  //border-top: 1px solid rgba(255, 255, 255, .3);
  //border-bottom: 1px solid rgba(255, 255, 255, .3);

  ul {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;

    li {
      @include eyebrow();
      list-style: none;
      color: #fff;
      margin: 0 5px;
      margin-top: 0;
      border: 1px solid rgba(255, 255, 255, .3);
      padding: 3px 15px;
    }
  }
}
