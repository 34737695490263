@import './Settings.scss';

.experience {
  .inner {
    display: flex;
  }

  .logos {
    display: flex;
    padding: 0;
    margin: 0 auto 10px auto;
    align-items: center;
    max-width: 1000px;

    @media (min-width: 768px) { 
      margin: 0 auto 40px auto;
    }

    li {
      list-style: none;
      margin: 0 10px;
      cursor: pointer;

      img {
        width: 100%;
        height: auto;
        opacity: 0.2;
        transition: all 0.4s;
      }
      &:hover {
        img {
          opacity: 1;
        }
      }
    }
  }
}