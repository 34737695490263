@import './Settings.scss';

.skill-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: row;
  margin: 0;
  padding: 0 20px;

  li {
    @include avenir(bold);
    display: flex;
    position: relative;
    flex: 1 1 auto;
    text-align: center;
    align-items: center;
    color: $primary-color;
    padding: 5px;
    transition: all 1s ease-out;
    justify-content: space-between;
    width: 50%;

    @media (min-width: 768px) {
      width: 33%;
    }

    @media (min-width: 992px) {
      width: 20%;
    }
    
    .inner {
      border: solid 2px darken($primary-color, 20%);
      padding: 10px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      .inner {
        color: $third-color;
        background: $primary-color;
      }
    }
  }
}